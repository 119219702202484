import React from 'react'
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react'
import { StaticQuery, graphql, Link } from 'gatsby'

import * as routes from '../../constants/routes'
import * as Theme from '../Elements/theme'

const CTASection = () => (
  <StaticQuery
    query={graphql`
      { 
        datoCmsHomePage {
          id
          heroImage {
            fixed(width: 1200) {
              ...GatsbyDatoCmsFixed
            }
          }
          heroHeading
          heroSubheading
          heroCtaText
          heroCtaLink
          offersHeading
          offersSubheading
          splitterImage {
            fixed(width: 1200) {
              ...GatsbyDatoCmsFixed
            }
          }
          splitterHeading
          splitterSubheading
          splitterCtaText
          splitterCtaLink
        }
      }
    `}
    render={data => (
      <>
        <Segment basic vertical style={{
          backgroundColor: Theme.Colors.darkBlue,
          paddingTop: '4.2rem',
          paddingBottom: '4rem'
          // minHeight: '35vh'
        }}>
          <Grid centered columns={2} stackable >
            <Grid.Row >
              <Grid.Column textAlign='center' largeScreen={6} computer={7} tablet={8} mobile={16}>
                <Header as='h2'
                  content="Potrzebujesz Pomocy?"
                  // inverted
                  style={{
                    fontFamily: 'Bangers',
                    fontSize: '3rem',
                    fontWeight: 'normal',
                    textShadow: '1px 1px 6px rgba(100, 100, 100, 0.7)',
                    color: Theme.Colors.offWhite
                  }}
                />
              </Grid.Column>
              <Grid.Column textAlign='center' largeScreen={4} computer={5} tablet={6} mobile={16}>
                <Link to={routes.CONTACT}>
                  <Button
                    color='blue'
                    size='large'
                    style={{
                      fontFamily: 'Playfair Display',
                      fontWeight: 'normal'
                    }}
                  >
                    {`${data.datoCmsHomePage.splitterCtaText}`}
                    <Icon name='right arrow' />
                  </Button>
                </Link>

              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <div style={{
          backgroundColor: 'rgba(255,238,0,1)',
          height: '6px'
        }}>

        </div>
      </>
    )}
  />
)

export default CTASection