import React from 'react';
// import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

import { ContentWrapper } from '../components/Elements';

import {
  Grid,
  Container,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';

import CTASection from '../components/CTA Section';

const OfferContext = React.createContext();

const offerTemplate = ({
  data: { datoCmsOffer: offerNode },
  location,
}) => {
  let days = offerNode.numberOfDays === 1 ? `dzień` : `dni`;

  return (
    <Layout>
      <SEO title={offerNode.nameOfOffer} />
      <Segment
        basic
        style={{
          minHeight: '50vh',
        }}
        vertical
        textAlign="center"
      >
        <Img
          fluid={offerNode.heroImage.fluid}
          imgStyle={{
            objectPosition: 'center ' + offerNode.heroImagePosition,
          }}
          alt={
            offerNode.heroImage.alt
              ? offerNode.heroImage.alt
              : offerNode.nameOfOffer
          }
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
          }}
        />
        <Header
          as="h2"
          content={offerNode.nameOfOffer}
          inverted
          style={{
            fontFamily: 'Bangers',
            fontSize: '3.5rem',
            fontWeight: 'normal',
            textShadow: '1px 1px 6px rgba(100, 100, 100, 0.7)',
            position: 'absolute',
            bottom: '4rem',
            width: '100%',
          }}
        />
      </Segment>

      <Grid
        centered
        stackable
        columns={3}
        style={{
          backgroundColor: '#eee',
        }}
      >
        <Grid.Row textAlign="center">
          <Grid.Column
            textAlign="center"
            largeScreen={4}
            computer={4}
            tablet={4}
            mobile={4}
          >
            <Header as="h5" icon>
              <Icon
                name="calendar alternate outline"
                color="teal"
                size="mini"
              />
              {offerNode.dataWyjazdu}
            </Header>
          </Grid.Column>
          <Grid.Column
            textAlign="center"
            largeScreen={4}
            computer={4}
            tablet={4}
            mobile={4}
          >
            <Header as="h5" icon>
              <Icon name="clock outline" color="teal" size="mini" />
              {offerNode.numberOfDays} {days}
            </Header>
          </Grid.Column>
          <Grid.Column
            textAlign="center"
            largeScreen={4}
            computer={4}
            tablet={4}
            mobile={4}
          >
            <Header as="h5" icon>
              <Icon name="users" color="teal" size="mini" />
              {`Ilość miejsc: ${offerNode.numberOfSpaces}`}
            </Header>
          </Grid.Column>
          <Grid.Column
            textAlign="center"
            largeScreen={4}
            computer={4}
            tablet={4}
            mobile={4}
          >
            <Header as="h5" icon>
              <Icon name="currency" color="teal" size="mini" />
              {`${offerNode.price} PLN`}
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ContentWrapper>
        <Grid centered stackable>
          <Grid.Row>
            <Grid.Column width={8}>
              <Container textAlign="center">
                <Img
                  fluid={
                    offerNode.insideImage
                      ? offerNode.insideImage.fluid
                      : null
                  }
                />
              </Container>
            </Grid.Column>
            <Grid.Column width={8}>
              <Container textAlign="justified">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      offerNode.insideDescriptionNode
                        .childMarkdownRemark.html,
                  }}
                ></div>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid centered columns={3} stackable>
          <Grid.Row>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  offerNode.longDescriptionNode.childMarkdownRemark
                    .html,
              }}
            ></div>
          </Grid.Row>

          {offerNode.featuresListNode.childMarkdownRemark.html !==
            '' ? (
            <Grid.Row textAlign="left" columns={1}>
              <Header
                size="huge"
                textAlign="center"
                dividing
                title="true"
              >
                Lista Atrakcji
              </Header>
            </Grid.Row>
          ) : null}

          {/* <Grid.Row>
              <Grid.Column > */}
          <Grid.Row textAlign="left" columns={1}>
            <div
              style={{ textAlign: 'left' }}
              dangerouslySetInnerHTML={{
                __html:
                  offerNode.featuresListNode.childMarkdownRemark.html,
              }}
            ></div>
          </Grid.Row>

          {/* </Grid.Column>
            </Grid.Row> */}
          {/* <Grid.Row>  */}
          <Segment basic padded>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  offerNode.textDepositNode.childMarkdownRemark.html,
              }}
            ></div>
          </Segment>
          {/* </Grid.Row> */}
          <Grid.Row>
            <Segment basic padded>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    offerNode.textFinePrintNode.childMarkdownRemark
                      .html,
                }}
              ></div>
            </Segment>
          </Grid.Row>
        </Grid>
      </ContentWrapper>
      {/* <OfferContext.Provider
        valuer={{
          name: offerNode.nameOfOffer,
          price: offerNode.price,
        }}
      >
        <Link to="rezerwacja">Rezerwacja</Link>
      </OfferContext.Provider> */}
      <CTASection />
    </Layout>
  );
};

export default offerTemplate;

export const pageQuery = graphql`
  query OfferBySlug($uid: String!) {
    datoCmsOffer(uid: { eq: $uid }) {
      uid
      nameOfOffer
      categories {
        name
      }
      shortDescription
      dataWyjazdu
      numberOfDays
      numberOfSpaces
      price
      insideImage {
        alt
        fixed(width: 400, height: 295) {
          ...GatsbyDatoCmsFixed
        }
        fluid(maxWidth: 400, maxHeight: 295) {
          ...GatsbyDatoCmsFluid
        }
      }
      insideDescriptionNode {
        childMarkdownRemark {
          html
        }
      }
      longDescriptionNode {
        childMarkdownRemark {
          html
        }
      }
      featuresListNode {
        childMarkdownRemark {
          html
        }
      }
      textDepositNode {
        childMarkdownRemark {
          html
        }
      }
      textFinePrintNode {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        alt
        fixed(width: 900, height: 460) {
          ...GatsbyDatoCmsFixed
        }
        fluid(
          imgixParams: {
            fit: "crop"
            w: "1400"
            h: "400"
            q: 100
            auto: "enhance"
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      heroImagePosition
    }
  }
`;
