import Carousel from 'nuka-carousel';
import React from 'react';

import { StaticQuery, graphql, Link } from 'gatsby'

// import * as routes from '../../constants/routes'
import * as Theme from '../Elements/theme'

import{ Button } from '../Elements'

import { Container, Icon, Header, Grid, Image } from 'semantic-ui-react'

import Img from 'gatsby-image'

const HeroCarousel = () => (
  <StaticQuery
    query={graphql`
      {
        datoCmsHomePage {
          id
          heroImage {
            fixed(width: 1200) {
              ...GatsbyDatoCmsFixed
            }
            fluid(maxWidth: 1200) {
              ...GatsbyDatoCmsFluid
            }
          }
          heroHeading
          heroSubheading
          heroCtaText
          heroCtaLink
          offersHeading
          offersSubheading
          splitterImage {
          
            fixed(width: 1200) {
              ...GatsbyDatoCmsFixed
            }
          }
          splitterHeading
          splitterSubheading
          splitterCtaText
          splitterCtaLink
        }

        allDatoCmsCompany {
          edges {
            node {
              id
              companyInfo {
                companyName
                linkWeb
                linkMore {
                  uid
                }
                companyLogo {
                  size
                  width
                  height
                  url
                  alt
                  title
                  fixed(imgixParams: {maxH: 200, maxW: 200, auto: "compress"}) {
                    ...GatsbyDatoCmsFixed
                  }
                  fluid {
                    ...GatsbyDatoCmsFluid
                  }
                }
              }
            }
          }
        }


      }
    `}
    render={data => (

      <div style={{ width: '100%', margin: 'auto', minHeight: '12rem', paddingTop: '2rem', paddingBottom: '2rem' }}>

{ console.log(data.allDatoCmsCompany.edges[0].node.companyInfo[0]) }

<div style={{ height: '20rem'}}>




              {/* <Img 
                fixed={ data.datoCmsHomePage.heroImage.fixed }
                
                style={{

                
                  zIndex: -1
                }}
              /> */}
</div>


        <Grid relaxed columns={ data.allDatoCmsCompany.edges[0].node.companyInfo.length }>

          { data.allDatoCmsCompany.edges[0].node.companyInfo.map(( node, index ) => (
            <Grid.Column key={ index } style={{ height: '20rem' }}>
            {/* <div key={ index }> */}
              <Img 
                fixed={ node.companyLogo.fixed }
                key={ index }
                link={ node.linkMore.uid }
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  width: "200px",
                  height: "200px",
                
                  zIndex: -1
                }}
              />
            {/* </div> */}
            </Grid.Column>
          ))}



          {/* <Grid.Column>
            <Image src='https://react.semantic-ui.com/images/wireframe/image.png' />
          </Grid.Column>
          <Grid.Column>
            <Image src='https://react.semantic-ui.com/images/wireframe/image.png' />
          </Grid.Column>
          <Grid.Column>
            <Image src='https://react.semantic-ui.com/images/wireframe/image.png' />
          </Grid.Column>
          <Grid.Column>
            <Image src='https://react.semantic-ui.com/images/wireframe/image.png' />
          </Grid.Column> */}
        </Grid>



         {/*  <Carousel
            // wrapAround={this.state.wrapAround},
            // easing="easeInOutElastic"
            autoplay={ true }
            speed={ 2000 }
            slideIndex={ 0 }
            length={ 1 }
            wrapAround={ true }
            underlineHeader={ false }
            slidesToShow={ 2 }
            cellAlign={ 'left' }
            transitionMode={ 'fade' }
            heightMode={ 'max' }
            withoutControls={ false }
            renderCenterLeftControls={({ previousSlide }) => (
              <Button icon size='massive' 
                onClick={previousSlide}
                style={{
                  backgroundColor: 'rgba(0,0,0,0)'
                }}>
                <Icon size='large' name='angle left' style={{ color: Theme.Colors.darkBlue }} />
              </Button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <Button icon size='massive' 
                onClick={nextSlide}
                style={{
                  backgroundColor: 'rgba(0,0,0,0)'
                }}>
                <Icon size='large'  name='angle right' style={{ color: Theme.Colors.darkBlue }} />
              </Button>
            )}
            >
{ console.log(data.allDatoCmsCompany.edges[0].node.companyInfo ) }

              { data.allDatoCmsCompany.edges[0].node.companyInfo.map(( node, index ) => (
                <div key={ index }>
                <Img 
                  fluid={ node.companyLogo.uploadId.fluid }
                  key={ index }
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                  
                    zIndex: -1
                  }}
                />

                

</div>

              ))}
        </Carousel> */}
       </div>
    )}
  />
)

export default HeroCarousel