import React from 'react'
import { Icon, Segment, Statistic } from 'semantic-ui-react'
import { StaticQuery, graphql, Link } from 'gatsby'

import * as routes from '../../constants/routes'
import * as Theme from '../Elements/theme'

const CTASection = () => (
  <StaticQuery
    query={graphql`
      { 
        datoCmsHomePage {
          id
          heroImage {
            fixed(width: 1200) {
              ...GatsbyDatoCmsFixed
            }
          }
          heroHeading
          heroSubheading
          heroCtaText
          heroCtaLink
          offersHeading
          offersSubheading
          splitterImage {
            fixed(width: 1200) {
              ...GatsbyDatoCmsFixed
            }
          }
          splitterHeading
          splitterSubheading
          splitterCtaText
          splitterCtaLink
        }
      }
    `}
    render={data => (
      <>
      <Segment basic vertical style={{
        backgroundColor: Theme.Colors.darkBlue,
        paddingTop: '4.2rem',
        paddingBottom: '4rem'
        // minHeight: '35vh'
      }}>
        <Statistic.Group widths='three' color='grey'>
          <Statistic>
            <Statistic.Value style={{ color: 'rgba(250,250,250,0.9' }}>
              <Icon name='thumbs up outline' /> 907
            </Statistic.Value>
            <Statistic.Label style={{ color: 'rgba(240,240,240,0.7', marginTop: '1rem' }}>Lajków</Statistic.Label>
          </Statistic>

          <Statistic>
            <Statistic.Value text style={{ color: 'rgba(250,250,250,0.9' }}>
              Pięć
              <br />
              Tyśięcy
            </Statistic.Value>
            <Statistic.Label style={{ color: 'rgba(240,240,240,0.7', marginTop: '1rem' }}>Kilometrów Przejechanych</Statistic.Label>
          </Statistic>

          <Statistic>
            <Statistic.Value style={{ color: 'rgba(250,250,250,0.9' }}>
              <Icon name='clock outline' /> 3000
            </Statistic.Value>
            <Statistic.Label style={{ color: 'rgba(240,240,240,0.7', marginTop: '1rem' }}>Godzin Super Zabawy!</Statistic.Label>
          </Statistic>
        </Statistic.Group>
      </Segment>
      <div style={{
        backgroundColor: 'rgba(255,238,0,1)',
        height: '6px'
      }}>
       
      </div>
      </>
    )}
  />
)

export default CTASection