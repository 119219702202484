import React, { Fragment } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo'
import { SignIn, SignUpLink, PasswordForgetLink } from '../components/Account'
import CTASection from '../components/CTA Section'

import { ContentWrapper, Header } from '../components/Elements'

import { Grid, Divider } from 'semantic-ui-react'

const SignInPage = () => (
  <Layout>
    <SEO title='Logowanie' />
    <Fragment>
      <ContentWrapper>
        <Grid centered columns={2} verticalAlign='middle' style={{ fontFamily: 'Playfair Display' }}>
          <Grid.Row>
            <Grid.Column largeScreen={8} computer={8} tablet={10} mobile={14} padded='vertically'>
              <Header title='true' spaced='true'>Zaloguj się</Header>
              <SignIn />
              <Divider hidden />
              <PasswordForgetLink />
              <SignUpLink />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContentWrapper>
    </Fragment>
    <CTASection />
  </Layout>
)

export default SignInPage