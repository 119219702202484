import React from 'react';

import logo from '../../images/kapiteam-logo-sm.png';

import { Map, Overlay } from 'pigeon-maps';
// import Overlay from 'pigeon-overlay'

import { Header } from '../Elements';

const MapComponent = props => {
  const { street, city } = props.location;
  const { latitude, longitude } = props.location.geolocation;

  return (
    <>
      <Header address="true">{street}</Header>
      <Header address="true" margin="true">
        {city}
      </Header>
      <Map center={[latitude, longitude]} zoom={16} height={272}>
        <Overlay anchor={[latitude, longitude]} offset={[0, 0]}>
          <img src={logo} width={50} height={52} alt="" />
        </Overlay>
      </Map>
    </>
  );
};

export default MapComponent;
