import React from 'react';

import Layout from '../components/layout';
import withAuthorization from '../components/Session/withAuthorization';

// const fromObjectToList = object =>
//   object
//     ? Object.keys(object).map(key => ({ ...object[key], index: key }))
//     : [];

class HomePageBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initFirebase: false,
      users: [],
    };
  }

  firebaseInit = () => {
    // const { users } = this.state

    if (this.props.firebase && !this.state.initFirebase && this.state.users) {
      this.props.firebase.onceGetUsers()
        .then(querySnapshot => {
          let userArr = []
          querySnapshot.forEach(doc => {
            userArr = userArr.concat(doc.data())
          })
          this.setState({
            users: userArr
          })
        })
      this.setState({
        initFirebase: true,
      });
    }
  };

  componentDidMount() {
    this.firebaseInit();

  }

  componentDidUpdate() {
    this.firebaseInit();
    // console.log(` users : `, this.state.users)
  }

  render() {
    const { users } = this.state;

    return (
      <Layout>
        <React.Fragment>
          <p>The Home Page is accessible by every signed in user.</p>

          {!!users.length && <UserList users={users} />}
        </React.Fragment>
      </Layout>
    );
  }
}

const UserList = ({ users }) => (
  <div>
    <h2>
      List of App User IDs (Saved on Sign Up in Firebase Database)
    </h2>
    {users.map((user, index) => (
      // console.log("users:" , user),
      <div key={index}>
        <h2>{user.name} - {user.email}</h2>
      </div>

    ))}
  </div>
);

const authCondition = authUser => !!authUser;

const HomePage = withAuthorization(authCondition)(HomePageBase);

export default HomePage