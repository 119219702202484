import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { Card, Grid, Image, Segment } from 'semantic-ui-react'
import Img from 'gatsby-image'

import { CardHeader, CardWrapper, Spacer } from '../components/Elements'

import HeroSmall from '../components/HeroSmall'
import CTASection from '../components/CTA Section'

const GalleryPage = ({ location }) => (
  <StaticQuery
    query={graphql`
    query {
      datoCmsHeadersSection(name: { eq: "galeria" }) {
        id
        name
        header
        heroImage {
          fixed(width: 900, height: 460) {
          ...GatsbyDatoCmsFixed
          }
          fluid(maxWidth: 1200, maxHeight: 460) {
          ...GatsbyDatoCmsFluid
          }
        }
      }
      allDatoCmsGallery {
        edges {
          node {
            uid
            nameOfGallery
            shortDescription
            heroImage {
              fixed(width: 900, height: 460) {
                ...GatsbyDatoCmsFixed
              }
              fluid(imgixParams: {fit: "crop", h: "400", w: "500", q: 65, auto: "enhance"}) {
              ...GatsbyDatoCmsFluid_tracedSVG
              }
            }
            heroImagePosition
          }
        }
      }
    }
  `}
    render={data => (
      <Layout>
        <SEO title={data.datoCmsHeadersSection.name} />
        <HeroSmall data={data.datoCmsHeadersSection} />
        <Grid centered stackable>
          <CardWrapper>
            <Segment basic padded='very'>
              <Card.Group stackable centered itemsPerRow={3}>
                {data.allDatoCmsGallery.edges.map((gallery, index) => {
                  const { heroImage: { fluid }, nameOfGallery, shortDescription, uid } = gallery.node
                  return (
                    <Card key={index} color='yellow' href={`${location.href}/${uid}`}>
                      <Image>
                        <Img fluid={fluid} />
                      </Image>
                      <Card.Content>
                        <CardHeader>{nameOfGallery}</CardHeader>
                        <Card.Meta><br /></Card.Meta>
                        <Card.Description>
                          <div dangerouslySetInnerHTML={{ __html: shortDescription }}></div>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  )
                })}
              </Card.Group>
            </Segment>
          </CardWrapper>
          <Spacer />
        </Grid>
        <CTASection />
      </Layout>
    )}
  />
)

export default GalleryPage