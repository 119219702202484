import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import { Button, Grid, Icon, Segment, Table } from 'semantic-ui-react'

import { Header } from '../../components/Elements'

import HeroSmall from '../../components/HeroSmall'
import CTASection from '../../components/CTA Section'

const DownloadsPage = () => (
  <StaticQuery
  query={graphql`
    query {
      datoCmsHeadersSection(name: { eq: "dokumenty-do-pobrania" }) {
        id
        name
        header
        heroImage {
          fixed(width: 900, height: 460) {
          ...GatsbyDatoCmsFixed
          }
          fluid(maxWidth: 1200, maxHeight: 460) {
          ...GatsbyDatoCmsFluid
          }
        }
      }
      allDatoCmsDownload {
        nodes {
          id
          download {
            file {
              url
              alt
              title
            }
            text
            textNode {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `}
  render={data => {

    const download = data.allDatoCmsDownload.nodes[0].download
    const headerInfo = data.datoCmsHeadersSection

  return (
    <Layout>
      <SEO title={ headerInfo.name } />
      <HeroSmall data={ headerInfo } />
      <Grid centered stackable>
        <div style={{ minHeight: '60vh', marginBottom: '6rem', marginTop: '2rem' }}>
          <Grid centered columns={1} stackable>
            <Grid.Row>
              <Table celled striped selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan='2'>Dokumenty do pobrania</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  { download.map(( item, index ) => (
                    <Table.Row>
                      <Table.Cell>{ item.file.title }</Table.Cell>
                      <Table.Cell>
                        <Button as='a' color='blue' icon labelPosition='left' href={ item.file.url } target='_blank' size='small'>
                          <Icon name='pdf file outline' size='large' />
                            Pobież plik
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan='2' textAlign='right'>Ilość plików: { download.length }</Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Grid.Row>
          </Grid>
        </div>
      </Grid>
      <CTASection />
    </Layout>
  )}}
/>
)

export default DownloadsPage