import React from 'react';
import { format } from 'date-fns'
import { pl } from 'date-fns/locale'

import { Feed } from 'semantic-ui-react'
import { useCollection } from '@nandorojo/swr-firestore'

export default function UserList() {
  const { data, update, error } = useCollection(`reviews`, { orderBy: ['created_time', 'desc']})

  if (error) return <h2>Error!</h2>
  if (!data) return <h2>Trwa Ładowanie...</h2>

  return (
    <Feed size='large'>
      { data.map(review => (
        <Feed.Event key={ review.created_time + review.reviewer }>
          <Feed.Label icon='user circle' />
          <Feed.Content>
            <Feed.Summary>
            { review.reviewer }
              <Feed.Date>{ format(new Date(review.created_time), "PP", { locale: pl }) }</Feed.Date>
            </Feed.Summary>
            <Feed.Extra text>
            {review.review_text}
            </Feed.Extra>
            <Feed.Meta>
              <Feed.Like>Źródło: { review.source }</Feed.Like>
            </Feed.Meta>
          </Feed.Content>
        </Feed.Event>
        )) 
      }
    </Feed>
  )
}