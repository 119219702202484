import React, { Fragment } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo'
import { PasswordForgetForm } from '../components/Account';
import CTASection from '../components/CTA Section'

import { ContentWrapper, Header } from '../components/Elements'
import { Grid } from 'semantic-ui-react'

const PasswordForgetPage = () => (
  <Layout>
    <SEO title='Zresetuj Hasło' />
    <Fragment>
      <ContentWrapper>
        <Grid centered columns={2} style={{ fontFamily: 'Playfair Display' }}>
          <Grid.Row>
            <Grid.Column>
              <Header title='true' spaced='true'>Resetuj hasło</Header>
              <PasswordForgetForm />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ContentWrapper>
    </Fragment>
    <CTASection />
  </Layout>
)

export default PasswordForgetPage