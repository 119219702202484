import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo'
import Img from 'gatsby-image'

import { ContentWrapper, Header } from '../components/Elements'
import { Grid, Container } from 'semantic-ui-react'

import CTASection from '../components/CTA Section'
import Lightbox from '../components/Lightbox'

const resortTemplate = ({ data: { datoCmsResort: resortNode } }) => {
  return (
    <Layout>
      <SEO title={resortNode.resortName} />
      <Container textAlign='justified' style={{ width: '100%', minHeight: '400px', height: '400px', margin: 'auto' }}>
        <Img
          fixed={resortNode.headerImage.large}
          imgStyle={{ objectPosition: 'center ' + resortNode.headerImagePosition }}
          style={{
            position: "absolute",
            left: 0,
            top: 80,
            width: "100%",
            height: '480px',
            minHeight: "480px",
            zIndex: -1
          }}
        />
        <Header
          sectiontitle='true'
          content={resortNode.resortName}
          inverted
          textAlign='left'
          style={{
            fontSize: '4.2rem',
            position: 'absolute',
            top: '350px',
            left: '12%',
          }}
        />
      </Container>
      <ContentWrapper>

        <Grid centered stackable>
          <Grid.Row>
            <Grid.Column width={8}>
              <Img fluid={resortNode.insideImage.fluid} />
            </Grid.Column>
            <Grid.Column width={8} >
              <Container textAlign='justified'>
                <div dangerouslySetInnerHTML={{ __html: resortNode.resortDescriptionNode.childMarkdownRemark.html }}></div>
              </Container>
            </Grid.Column>
          </Grid.Row>

        </Grid>
        <Grid centered columns={3} stackable>
          <Header size='huge' textAlign='left' dividing titledark='true' titlepadded='true'>Lista Atrakcji</Header>
          <Grid.Row textAlign='left' columns={1}>

            <div dangerouslySetInnerHTML={{ __html: resortNode.resortFeaturesNode.childMarkdownRemark.html }}></div>

          </Grid.Row>

          {resortNode.resortGallery.length > 0 ?
            <>
              <Header size='huge' textAlign='left' dividing titledark='true' titlepadded='true'>Galeria Zdjęć</Header>
              <Grid.Row columns={1} >
                <Lightbox images={resortNode.resortGallery} />
              </Grid.Row>
            </>
            :
            <Header size='huge' textAlign='left' dividing></Header>
          }



        </Grid>
      </ContentWrapper>
      <div>
      </div>
      <CTASection />
    </Layout>
  );
};

export default resortTemplate;

export const pageQuery = graphql`
  query ResortBySlug($uid: String!) {
    datoCmsResort(uid: { eq: $uid }) {
      id
      uid
      resortName
      headerImage {
        large: fixed(width: 900, height: 460) {
          ...GatsbyDatoCmsFixed_tracedSVG
        }
        medium: fixed(width: 300, height: 300) {
          ...GatsbyDatoCmsFixed_tracedSVG
        }
      }
      headerImagePosition
      insideImage {
        fluid(maxWidth: 400, maxHeight: 295) {
          ...GatsbyDatoCmsFluid_tracedSVG
        }
      }
      resortDescriptionNode {
        childMarkdownRemark {
          id
          html
        }
      }
      resortFeaturesNode {
        childMarkdownRemark {
          id
          html
        }
      }
      resortGallery {
        thumbnail: fixed(imgixParams: {fit: "crop", h: "169", w: "300", q: 65, auto: "enhance"}) {
          ...GatsbyDatoCmsFixed_tracedSVG
        }
        large: fluid(imgixParams: {maxH: 450, maxW: 900, q: 70, auto: "enhance"}) {
        ...GatsbyDatoCmsFluid_tracedSVG
        }
      }
    }
  }
`;
