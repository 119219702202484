module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://b5c6c83b715d4527b281bd4aab720e6f@sentry.io/1450144"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.gstatic.com"],"web":[{"name":"Playfair Display","file":"https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap"},{"name":"Bangers","file":" https://fonts.googleapis.com/css2?family=Bangers&display=swap"},{"name":"BenchNine","file":"https://fonts.googleapis.com/css2?family=BenchNine&display=swap"},{"name":"Lato","file":" https://fonts.googleapis.com/css2?family=Lato&display=swap"},{"name":"Montserrat","file":" https://fonts.googleapis.com/css2?family=Montserrat&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"kapiteam","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/kapiteam-logo-lg.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"29480a499032131e946e49cd3d675d71"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-136980574-1","head":false,"anonymize":true},
    }]
