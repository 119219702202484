import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HeroSmall from '../components/HeroSmall'
import CTASection from '../components/CTA Section'

import { ContentWrapper, Header, Segment } from '../components/Elements'

import { Button, Container, Grid, Icon } from 'semantic-ui-react'

const AboutPage = () => (
  <StaticQuery
    query={graphql`
      {
        datoCmsAboutPage {
          header
          headerImage {
            fixed(width: 900, height: 460) {
            ...GatsbyDatoCmsFixed
            }
            fluid(maxWidth: 1200, maxHeight: 460) {
            ...GatsbyDatoCmsFluid
            }
          }
          teamPhoto {
            fixed(width: 400, height: 295) {
              ...GatsbyDatoCmsFixed
            }
            fluid(maxWidth: 400, maxHeight: 295) {
              ...GatsbyDatoCmsFluid
            }
          }
          teamDescriptionNode {
            childMarkdownRemark {
              html
            }
          }
          companyInfo {
            textNode {
              childMarkdownRemark {
                html
              }
            }
            file {
              title
              url
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <SEO title='O Nas' />
        <HeroSmall data={ data.datoCmsAboutPage } />
        <ContentWrapper>
        <div style={{ fontFamily: 'Playfair Display', marginBottom: '8rem' }}>
          <Grid centered stackable columns={2}>
            <Grid.Row centered>
              <Grid.Column textAlign='left' width={8}>
                  <Img fluid={ data.datoCmsAboutPage.teamPhoto.fluid } />
              </Grid.Column>
              <Grid.Column  width={8}>
                <Container textAlign='left'>
                  <Segment quote='true'>
                    <div dangerouslySetInnerHTML={{ __html: data.datoCmsAboutPage.teamDescriptionNode.childMarkdownRemark.html }}></div>
                  </Segment>
                </Container>
              </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid centered columns={1} stackable>
              <Grid.Row>
                <Segment padded basic textAlign='left'>
                { data.datoCmsAboutPage.companyInfo.map(( item, index ) => (
                  <Segment key={ index } basic padded>
                    <Header address='true'>
                      <div dangerouslySetInnerHTML={{ __html: item.textNode.childMarkdownRemark.html }}></div>
                    </Header>
                    <Segment basic>
                      <Button as='a' color='teal' icon labelPosition='left' href={ item.file.url } target='_blank'>
                        <Icon name='pdf file outline' size='large' />
                        <Header button='true'>
                          Pobież plik
                        </Header>
                      </Button>
                    </Segment>
                  </Segment>
                ))}
                </Segment>
              </Grid.Row>
            </Grid>
        </div>
        </ContentWrapper>
        <CTASection />
      </Layout>
    )}
  />
)

export default AboutPage