import PropTypes from 'prop-types';
import React from 'react';
import Headroom from 'react-headroom';
import { Link } from 'gatsby';
import { Menu, Dropdown } from 'semantic-ui-react';

import AuthUserContext from '../Session/AuthUserContext';
import { Main } from '../../constants/menu';
import * as routes from '../../constants/routes';
import HeaderKontakt from '../HeaderKontakt';
import Logo from '../Header/Logo';
import { SignOut } from '../Account';
// import { log } from 'util';

const style = {
  activeMain: {
    borderBottom: `6px solid rgba(255,225,31,1)`,
  },
  activeSubmenu: {
    borderLeft: `6px solid rgba(255,225,31,1)`,
    textIndent: `10px`,
  },
  blank: {},
  menu: {
    fontFamily: 'Playfair Display',
    fontWeight: '700',
    marginTop: '0px',
    borderRadius: '0px',
    minHeight: '118px',
  },
  icon: {
    float: 'initial',
  },
};

const DesktopContainer = props => {
  const { children, data } = props;
  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <>
          <Headroom
            upTolerance={10}
            downTolerance={10}
            style={{ zIndex: '99' }}
          >
            <HeaderKontakt />
            <Menu
              size="huge"
              color="yellow"
              borderless
              stackable
              style={style.menu}
            >
              <Menu.Item as={Link} to="/" header>
                <Logo />
              </Menu.Item>
              <Menu.Menu position="right">
                {Main(data).map((item, index) =>
                  item.subitems.length > 0 ? (
                    <Dropdown
                      key={index}
                      item
                      simple
                      activeStyle={style.activeMain}
                      text={item.caption}
                      // as={Link}
                      // to={`/oferty`}
                      // state={{ source: 'oferty' }}
                    >
                      <Dropdown.Menu
                        style={{
                          borderTop: '3px solid rgba(255,225,31,1)',
                        }}
                      >
                        {item.subitems.map((sItem, sIndex) => {
                          return sItem.length > 0 ? (
                            sItem.map((ssItem, ssIndex) => {
                              return (
                                <div key={ssIndex}>
                                  <Dropdown.Item>
                                    <Dropdown
                                      simple
                                      text={ssItem.name}
                                      activeStyle={
                                        index !== 0
                                          ? style.activeSubmenu
                                          : style.blank
                                      }
                                    >
                                      <Dropdown.Menu>
                                        {ssItem.categories.map(
                                          (scItem, scIndex) => {
                                            return (
                                              <Dropdown.Item
                                                as={Link}
                                                key={scIndex}
                                                to={scItem.link}
                                                text={scItem.caption}
                                                activeStyle={
                                                  index !== 0
                                                    ? style.activeSubmenu
                                                    : style.blank
                                                }
                                                state={{
                                                  source: `${ssItem.name}: ${scItem.caption}`,
                                                }}
                                              />
                                            );
                                          },
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </Dropdown.Item>
                                </div>
                              );
                            })
                          ) : (
                            <Dropdown.Item
                              as={Link}
                              key={sIndex}
                              to={sItem.link}
                              text={sItem.caption}
                              activeStyle={
                                index !== 0
                                  ? style.activeSubmenu
                                  : style.blank
                              }
                              state={{ source: sItem.caption }}
                            />
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <Menu.Item
                      key={index}
                      as={Link}
                      to={item.link}
                      activeStyle={style.activeMain}
                      partiallyActive={true}
                    >
                      {item.caption}
                    </Menu.Item>
                  ),
                )}
              </Menu.Menu>
              {authUser ? (
                <Menu.Menu>
                  <Menu.Item
                    as={Link}
                    to={routes.ACCOUNT}
                    activeStyle={style.activeMain}
                    partiallyActive={true}
                  >
                    Twoje Konto
                  </Menu.Item>
                  <Menu.Item>
                    <SignOut />
                  </Menu.Item>
                </Menu.Menu>
              ) : (
                <Menu.Item
                  as={Link}
                  to={routes.SIGN_IN}
                  activeStyle={style.activeMain}
                  partiallyActive={true}
                >
                  Zaloguj Się
                </Menu.Item>
              )}
            </Menu>
          </Headroom>
          {children}
        </>
      )}
    </AuthUserContext.Consumer>
  );
};

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

export default DesktopContainer;
