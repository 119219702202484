import React from 'react';
import PropTypes from "prop-types"
import Sentry from '../components/Utilities/Sentry'
import { useStaticQuery, graphql } from "gatsby"

import 'firebase/firestore'
import 'firebase/auth'
import { Fuego, FuegoProvider } from '@nandorojo/swr-firestore'

import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import CookiesPopup from '../components/CookiesPopup'

import 'semantic-ui-css/semantic.min.css'

const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
}

const prodConfig = {
  apiKey: "AIzaSyBT7e0VWxFHw_8dr2FMlKCfS6gSvuCWhuc",
  authDomain: "kapiteam-agencja-turystyczna.firebaseapp.com",
  databaseURL: "https://kapiteam-agencja-turystyczna.firebaseio.com",
  projectId: "kapiteam-agencja-turystyczna",
  storageBucket: "kapiteam-agencja-turystyczna.appspot.com",
  messagingSenderId: "114752603719"
};

// const fuego = new Fuego(prodConfig)

let fuego = null

const getFuego = () => {
  if (typeof window !== 'undefined') {
    if (fuego) return fuego
    fuego = new Fuego(prodConfig)
    return fuego
  }

  return null;
}


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  getFuego()
  return (
    <>
      <FuegoProvider fuego={fuego}>
        <Sentry>
          <CookiesPopup />
          <Navigation>
            {children}
            <Footer />
          </Navigation>
        </Sentry>
      </FuegoProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout